import { ADOProject, ADOTeam } from '../../containers/integrations/ado/ado.type';
import { ExternalBoard, ExternalProject } from '../projects-client/projects-client.type';

enum IntegrationAuthType {
  Oauth = 'oauth',
  Basic = 'basic',
  Token = 'token',
}

type Integration = {
  auth_type: IntegrationAuthType;
  auth_url: string;
  description: string;
  id: string;
  instructions: string;
  logo: string;
  name: string;
  system_type: string;
  enabled?: boolean;
};

type GetBoardStatusesResponse = {
  statuses: string[];
  project_url: string;
};

type completeGithubHandshakeResponse = {
  [key: string]: string;
};

enum ExternalService {
  Jira = 'jira',
  JDC = 'jdc',
  Github = 'github',
  Figma = 'figma',
  ADO = 'ado',
  Gitlab = 'gitlab',
  Confluence = 'confluence',
  Aha = 'aha',
  Bitbucket = 'bitbucket',
  Notion = 'notion',
  Asana = 'asana',
  ADO_Repos = 'ado_repository',
}

enum Methodology {
  Scrum = 'scrum',
  Kanban = 'kanban',
}

type GetBoardStatusesPayload =
  | {
      service: ExternalService.Jira;
      project_id: string | null;
      board_id: string | null;
      system_access_id: string | null;
    }
  | {
      service: ExternalService.ADO;
      project_id: string | null;
      organization_name: string | null;
      system_access_id: string | null;
      team_id: string | null;
    }
  | {
      service: ExternalService.JDC;
      subproject_id: string | null;
    };

type SetStatusMappingPayload = {
  service_name: string;
  project: ExternalProject | ADOProject | IntegrationJDC;
  board: ExternalBoard | ADOTeam | BoardJDC;
  org_id: string;
  backlog_section: string[];
  blocked_section: string[];
  in_progress_section: string[];
  in_review_section: string[];
  in_test_section: string[];
  ready_for_deployment_section: string[];
  done_section: string[];
  project_url?: string;
  configuration_state?: string;
};

enum IntegrationType {
  ADO = 'ado',
  GITHUB = 'github',
  JIRA = 'jira',
  JDC = 'jira_data_center',
}

type IntegrationEntry = {
  id: string;
  integration_type: IntegrationType;
  user_id: string;
  user_name: string;
  projects: Project[];
  subprojects: Subproject[];
  last_completed_at: string | null;
  last_run_status: string | null;
  last_run_time: string | null;
  manually_prioritized: boolean;
  current_stage: CurrentStage;
};

type Project = {
  id: string;
  name: string;
  subproject_ids: string[];
};

type Subproject = {
  id: string;
  name: string;
  project_id: string;
  project_name: string;
  configuration_state?: string;
  last_data_fetch: string;
  subproject_settings_id?: string;
  subproject_settings_name?: string;
};

enum CurrentStage {
  UNKNOWN = 'UNKNOWN',
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  INTERRUPT = 'INTERRUPT',
  FAILED = 'FAILED',
}

type IntegrationRefreshData = {
  message: string;
  status: number;
};

type IntegrationJDC = {
  name: string;
  id: number;
  internal_id: string;
  key: string;
  boards: BoardJDC[];
};

type BoardJDC = {
  name: string;
  id: number;
  internal_id: string;
};

type StartIntegrationProcessPayload = {
  organization_id: string;
  service: ExternalService;
  state: string;
  system_access_id: string;
  integration_id: string | null;
};

export { CurrentStage, ExternalService, IntegrationAuthType, IntegrationType, Methodology };
export type {
  BoardJDC,
  completeGithubHandshakeResponse,
  GetBoardStatusesPayload,
  GetBoardStatusesResponse,
  Integration,
  IntegrationEntry,
  IntegrationJDC,
  IntegrationRefreshData,
  Project,
  SetStatusMappingPayload,
  StartIntegrationProcessPayload,
  Subproject,
};
